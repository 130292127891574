import uPlot from "uplot";
import {VerticalAnnotation} from "../../../live-monitoring/generated-src";
import "./VerticalAnnotationPluginStyle.scss"
import {
    colorChartsPaletteCategorical10,
    colorChartsPaletteCategorical5,
    colorChartsPaletteCategorical6,
    colorChartsPaletteCategorical7, colorChartsPaletteCategorical8, colorChartsPaletteCategorical9
} from "@amzn/awsui-design-tokens";

const VERTICAL_CHART_FILL = [
    colorChartsPaletteCategorical5,
    colorChartsPaletteCategorical6,
    colorChartsPaletteCategorical7,
    colorChartsPaletteCategorical8,
    colorChartsPaletteCategorical9,
    colorChartsPaletteCategorical10
]

export const verticalAnnotationPlugin = (marks: VerticalAnnotation[]): uPlot.Plugin => ({
    hooks: {
        init:
            (u: uPlot) => {
                let legendElement = u.root.querySelector(".u-legend, table") as HTMLTableElement;
                marks.forEach((annotation, idx) => addToLegend(u, annotation, legendElement, annotation.color || VERTICAL_CHART_FILL[idx]))
            },
        drawClear:
            (u: uPlot) => {
                u.over.querySelectorAll('.u-mark-x').forEach(el => el.remove())
                marks.forEach((annotation, idx) => {
                    if (

                        u.scales.x.max && u.scales.x.min &&
                        (annotation.startTime >= u.scales.x.min &&
                            annotation.endTime <= u.scales.x.max
                            ||
                            annotation.startTime >= u.scales.x.min &&
                            annotation.startTime <= u.scales.x.max
                            ||
                            annotation.endTime >= u.scales.x.min &&
                            annotation.endTime <= u.scales.x.max)
                    ) {
                        placeMark(u, annotation, annotation.color || VERTICAL_CHART_FILL[idx], u.scales.x.min, u.scales.x.max);
                    }
                });
            }
    },
});


function placeMark(u: uPlot, mark: VerticalAnnotation, fill: string, minStartTime: number, maxEndTime: number) {
    let markEl = document.createElement('div');
    markEl.classList.add('u-mark-x','u-annotation-mark');
    const leftBorderX = Math.round(u.valToPos(Math.max(mark.startTime, minStartTime), 'x'));
    const width = mark.endTime > mark.startTime ? Math.round(u.valToPos(Math.min(mark.endTime, maxEndTime), 'x')) - leftBorderX : 0;
    Object.assign(markEl.style, {
        left: `${leftBorderX}px`,
        width: `${width}px`,
        borderLeft: `2px  solid  ${fill}`,
        borderRight: mark.endTime > mark.startTime ? `2px  solid ${fill}` : null,
        background: fill,
    });

    u.over.appendChild(markEl);
}

function addToLegend(u: uPlot, mark: VerticalAnnotation, legendEl: HTMLTableElement, fill: string) {
    const annotationLegendEl = legendEl.insertRow();
    annotationLegendEl.classList.add('u-series');
    const annotationContainerEl = annotationLegendEl.insertCell();


    const colorKey = document.createElement("div");
    colorKey.classList.add('u-marker','u-annotation-mark');
    Object.assign(colorKey.style, {
        border: '2px solid ' + fill,
    })

    const stringKey = document.createElement("div");
    stringKey.classList.add('u-label');
    stringKey.textContent = mark.name;

    const headerEl = document.createElement("th")
    annotationContainerEl.appendChild(headerEl)
    headerEl.appendChild(colorKey);
    headerEl.appendChild(stringKey);
}